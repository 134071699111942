import React from "react";
import { Image } from "../Image";
import { Close } from "../Close";
import { motion } from "framer-motion";
import { modalAni } from "../animation";
import { Cardpattern } from "../Cardpattern";
function Modal({ Layout, setLayout, nonMutable }) {
  return (
    <>
      <motion.div className="modal--container">
        <motion.div
          className="modal"
          magic
          layoutId={Layout.id}
          transition={{
            duration: 0.6,
          }}
        >
          <motion.div
            className="tips--area"
            variants={modalAni}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Image
              classId={Layout.title}
              image={Layout.img}
              alt="teeth illustrations"
            />
            <motion.p>{Layout.tip}</motion.p>
          </motion.div>
          <motion.button
            className="close--button"
            onClick={() => {
              setLayout({ ...nonMutable });
            }}
            aria-label="close"
          >
            <Close />
          </motion.button>
          <div className="pattern--modal">
            <Cardpattern />
          </div>
        </motion.div>
      </motion.div>
    </>
  );
}

export { Modal };
