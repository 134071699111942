import React, { useLayoutEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { container, item } from "./loading";
function Loader({ setLoading, Loading }) {
  const [Theme, setTheme] = useState({ theme: "" });

  useLayoutEffect(() => {
    const color = document.body.className;
    setTheme({ theme: color });
   
  }, [Theme.theme]);

  return (
    <>
      <motion.div
        className="loader"
        variants={container}
        initial="hidden"
        animate="show"
        exit="exit"
        onAnimationComplete={() => {
          setLoading({ state: false });
        }}
      >
        <motion.div className="image--wrapper" variants={item}>
          {Theme.theme === "light" ? (
            <StaticImage src="../images/loaderLogo.png" alt="loader" />
          ) : (
            <StaticImage src="../images/logodark.png" alt="loader" />
          )}
        </motion.div>
      </motion.div>
    </>
  );
}

export { Loader };
