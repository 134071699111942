import React from "react";
import { Image } from "../Image";
import {useStaticQuery, graphql} from 'gatsby';
function ClincImages() {

  const data = useStaticQuery(graphql`
    query {
      allContentfulClinicImages {
        nodes {
          id
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const { allContentfulClinicImages:{nodes} } = data;

  return (
    <>
      <div className="clinic--images--wrapper">
        <ul>
          {nodes.map((n) => {
            return (
              <li key={n.id}>
                <Image classId="clinic--images" image={n.image}  alt='clinic'/>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export { ClincImages };
