import React from "react";
import { TopSection2 } from "./TopSectin2";
import { StaticImage } from "gatsby-plugin-image";
function TimingSection() {
  return (
    <>
      <div className="Timings--wrapper">
        <div className='timings--inner--wrapper'>
          <TopSection2 />
        </div>

        <div className="pattern--image">
          <StaticImage src="./HomepageImages/pattern.png" alt="pattern" />
        </div>
      </div>
    </>
  );
}

export { TimingSection };
