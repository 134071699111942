import React, { useState } from "react";
import { Header } from "./Header";
import { About } from "./About";
import { TipsSection } from "./TipsSection";
import { TimingSection } from "./TimingSection";
import { ClinicSection } from "./ClinicSection";
import { BackToTop } from "../BackTotop";
import { Testimonial } from "./Testimonial";
import { Theme } from "../Theme";

function HomePage() {
  const [Inter, setInter] = useState({ state: true });

  return (
    <>
      <main className="homepage--main" id="home">
        <Header setInter={setInter} />
        <About />
        <TipsSection />
        <TimingSection />
        <ClinicSection />
        <Testimonial />
        <div className="theme--and--top">
          {!Inter.state ? <BackToTop id="home" /> : ""}
          <Theme />
        </div>
      </main>
    </>
  );
}

export { HomePage };
