import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Image } from "../Image";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { Modal } from "./Modal";
const nonMutable = {
  id: "",
  state: false,
  title: "",
  img: "",
  tip: "",
};

function CardsSection() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTipscards {
        nodes {
          tips
          tipsTitle
          toothImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);
  const {
    allContentfulTipscards: { nodes },
  } = data;

  const [Layout, setLayout] = useState({ ...nonMutable });

  return (
    <>
      <div className="tips--cards section">
        <AnimateSharedLayout type="crossfade">
          <ul>
            {nodes.map((n) => {
              return (
                <motion.li
                  className="tips"
                  layoutId={n.tipsTitle}
                  key={n.tipsTitle}
                  id={`${n.tipsTitle}`}
                  transition={{
                    duration: 1.5,
                  }}
               
                  onTap={() => {
                    setLayout({
                      id: n.tipsTitle,
                      title: n.tipsTitle,
                      img: n.toothImage,
                      tip: n.tips,
                      state: true,
                    });
                  }}
                >
                  <Image classId={n.tipsTitle} image={n.toothImage}  alt='teeth illustrations'/>
                  <motion.p>{n.tipsTitle}</motion.p>
                </motion.li>
              );
            })}
          </ul>
          <AnimatePresence>
            {Layout.state && (
              <Modal
                Layout={Layout}
                setLayout={setLayout}
                nonMutable={nonMutable}
              />
            )}
          </AnimatePresence>

          <div className="card--guide">
            <p>Tap the cards to see more details</p>
          </div>
        </AnimateSharedLayout>
      </div>
    </>
  );
}

export { CardsSection };
