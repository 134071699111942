import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Rating } from "./Rating";
import { TestimonialLogo } from "./TestimonialLogo";
function Testimonial() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTestimonial {
        nodes {
          id
          name
          reviews {
            reviews
          }
          rating
        }
      }
    }
  `);

  const {
    allContentfulTestimonial: { nodes },
  } = data;
  return (
    <>
      <div className="Testimonial--wrapper">
        <div className="Testimonial--inner--wrapper">
          <TestimonialLogo />
          <div className="Testi-bottom">
            <h2>Testimonial</h2>
            {nodes.map((n) => {
              return (
                <div className="container--wrap" key={n.id}>
                  <h3>{n.name}</h3>
                  <p>{n.reviews.reviews}</p>
                  <Rating number={n.rating} ley={n.id} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export { Testimonial };
