import React from "react";

function Cardpattern() {
  return (
    <>
      <svg
        width={1200}
        height={372}
        viewBox="0 0 1200 372"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id='card--pattern'
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 159.429L20 194.857C40 230.286 80 301.143 120 301.143C160 301.143 200 230.286 240 177.143C280 124 320 88.5714 360 106.286C400 124 440 194.857 480 221.429C520 248 560 230.286 600 203.714C640 177.143 680 141.714 720 141.714C760 141.714 800 177.143 840 141.714C880 106.286 920 0 960 0C1000 0 1040 106.286 1080 132.857C1120 159.429 1160 106.286 1180 79.7143L1200 53.1429V372H1180C1160 372 1120 372 1080 372C1040 372 1000 372 960 372C920 372 880 372 840 372C800 372 760 372 720 372C680 372 640 372 600 372C560 372 520 372 480 372C440 372 400 372 360 372C320 372 280 372 240 372C200 372 160 372 120 372C80 372 40 372 20 372H0V159.429Z"
         
        />
      </svg>
    </>
  );
}

export { Cardpattern };
