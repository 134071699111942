import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Legends } from "./Legends";
function TopSection2() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulClinicTimings {
        nodes {
          heading
          body
          body2
          timings
        }
      }
    }
  `);

  const {
    allContentfulClinicTimings: {
      nodes: [{ heading, body, body2, timings }],
    },
  } = data;
  return (
    <>
      <div className="timings--topSection">
        <h2>{heading}</h2>
        <p>{body}</p>
        <p>{body2}</p>
      </div>

      <div className="timings--wrapper">
        <ul className="timings">
          {timings.map((n) => {
            return (
              <li className="time" key={n} aria-label=" Clinic time">
                <p>{n}</p>
              </li>
            );
          })}
        </ul>
        <Legends />
      </div>
    </>
  );
}

export { TopSection2 };
