import React from "react";

function ClinicTopSection({ span, span2, span3, body }) {
  return (
    <>
      <div className="clinic--TopSection">
        <h2>
          {span} <span className="highlight">{span2}</span> {span3}
        </h2>
        <p>{body}</p>
      </div>
    </>
  );
}

export { ClinicTopSection };
