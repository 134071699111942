import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Image } from "../Image";
function TestimonialLogo() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTestimonialLogo {
        nodes {
          id
          logo {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const {
    allContentfulTestimonialLogo: {
      nodes: [{ logo }],
    },
  } = data;
  return (
    <>
      <div className="test-logo-warpper">
        <div className="test-logo-outer">
          <Image image={logo} alt="logo" />
        </div>
      </div>
    </>
  );
}

export { TestimonialLogo };
