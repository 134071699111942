import React from "react";
import { useStaticQuery, graphql } from "gatsby";
function TopSection() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTipssection {
        nodes {
          heading
          body
          span
        }
      }
    }
  `);

  const {
    allContentfulTipssection: {
      nodes: [{heading, body, span}]
    },
  } = data;
  return (
    <>
      <div className="top--section">
        <h2>
          {heading} <span>{span}</span>
        </h2>
        <p>{body}</p>
      </div>
    </>
  );
}

export { TopSection };
