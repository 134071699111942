import React from "react";
import { Star } from "../Star";
function Rating({ number, ley }) {
  return (
    <>
      <div className="rating--wrapper">
        <ul>
          {[...Array(number)].map((index, i) => {
            return <li key={`star--${i}`}>{<Star />}</li>;
          })}
        </ul>
      </div>
    </>
  );
}

export { Rating };
