import React from "react";
import { AboutText } from "./AboutText";
import { HeroImage } from "./HeroImage";
import { useStaticQuery, graphql } from "gatsby";
function About() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAboutMe {
        nodes {
          heading
          greeting
          body {
            body
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          image2 {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  `);

  const {
    allContentfulAboutMe: {
      nodes: [
        {
          heading,
          greeting,
          body: { body },
          image,
          image2,
        },
      ],
    },
  } = data;
  return (
    <>
      <div className="about--wrapper">
        <div className="about--container">
          <AboutText title={heading} body={body} body2={greeting} />
          <div className="about--image--1">
            <HeroImage
              classId="profile--image"
              image={image}
              alt="doctor profile"
            />
          </div>
          <div className="about--image--2">
            <HeroImage
              classId="profile--image--2"
              image={image2}
              alt="doctor profile"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export { About };
