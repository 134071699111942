import * as React from "react";
import { HomePage } from "../Components/homepage/HomePage";
import { Footer } from "../Components/Footer";
import { Loader } from "../Components/Loader";
import { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
const IndexPage = () => {
  const [Loading, setLoading] = useState({ state: true });


  return (
    <>
    
      <AnimatePresence exitBeforeEnter>
        {Loading.state ? (
          <motion.div className="loader--wrapper--wrapper" key="loader">
            <Loader setLoading={setLoading} Loading={Loading}/>
          </motion.div>
        ) : (
          <motion.div className="home--page--wrapper ">
            <HomePage />
            <Footer />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default IndexPage;
