import React from "react";
import { TopSection } from "./TopSection";
import { CardsSection } from "./CardsSection";
function TipsSection() {
  return (
    <>
      <div className='tips--outer--wrapper'>
        <div className="tips--wrapper">
          <TopSection />
          <CardsSection />
        </div>
      </div>
    </>
  );
}

export { TipsSection };
