const container = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  hidden: { opacity: 0 },

  exit: {
    opacity: 0,
   
    transition: {
      duration: 0.4,
      delay: 1.5,
    },
  },
};

const item = {
  show: {
    scale: [1.5, 1],

    transition: {
      duration: 1.4,
      delay: 0.2,
    },
  },

};

export { container, item };
