import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { HeroText } from "./HeroText";
import { HeroImage } from "./HeroImage";
function HeroContainer() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHeader {
        nodes {
          title
          body
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          headerImage2 {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          pattern {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  `);

  const {
    allContentfulHeader: {
      nodes: [{ title, body, headerImage, headerImage2 }],
    },
  } = data;

  return (
    <>
      <div className="hero--container">
        <HeroText
          title={title}
          body={body}
          goto="services"
          value="Our Services"
          id="header--link"
        />
        <HeroImage
          image={headerImage}
          classId="hero-image"
          alt="girl smiling"
        />
        <HeroImage
          image={headerImage2}
          classId="hero-image2"
          alt="girl smiling"
        />
      </div>
    </>
  );
}

export { HeroContainer };
