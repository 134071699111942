import React from "react";

function AboutText({ title, body, body2 }) {
  return (
    <>
      <div className="about--text">
        <h2>{title}</h2>
        <p>{body2}</p>
        <p>{body}</p>
      </div>
    </>
  );
}

export { AboutText };
