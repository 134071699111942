import React from "react";

function Legends() {
  return (
    <>
      <div className="legends--section">
        <div className="available">
          <span className="circle"></span>
          <p>available</p>
        </div>
        <div className="break">
          <span className="circle"></span>
          <p>Lunch Break</p>
        </div>
      </div>
    </>
  );
}

export { Legends };
