import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ClinicTopSection } from "./ClincTopSection";
import { ClincImages } from "./ClincImages";
function ClinicSection() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulClinic {
        nodes {
          span
          span2
          span3
          body
        }
      }
    }
  `);

  const {
    allContentfulClinic: {
      nodes: [{ span, span2, span3, body }],
    },
  } = data;
  return (
    <>
      <div className="clinic--outer--wrapper">
        <div className="clinic--gallery--wrapper">
          <ClinicTopSection
            span={span}
            span2={span2}
            span3={span3}
            body={body}
          />
          <ClincImages />
        </div>
      </div>
    </>
  );
}

export { ClinicSection };
